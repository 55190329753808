import {isEmptyValue} from '../../utils/public';
import request from '../../utils/request';
import {Toast} from "vant";

const state = {
    status:[],
    action:[],
};

const getters = {};

const mutations = {};

const actions = {
    // 工单流程
    getExploreStatus(){
        return new Promise((resolve, reject) => {
            if(isEmptyValue(state.status)){
                request.post('/api/common/enums', {type: 'explore_work_status'}).then(result => {
                    if (result.code === 0) {
                        state.status = result.data;
                        resolve();
                    } else {
                        Toast('数据加载异常');
                        reject();
                    }
                }).catch(e => {
                    Toast('数据加载异常');
                    console.log(e);
                    reject();
                })
            } else {
                resolve();
            }
        });
    },
    // 工单操作
    getExploreAction(){
        return new Promise((resolve, reject) => {
            if(isEmptyValue(state.action)){
                request.post('/api/common/enums', {type: 'explore_work_action'}).then(result => {
                    if (result.code === 0) {
                        state.action = result.data;
                        resolve();
                    } else {
                        Toast('数据加载异常');
                        reject();
                    }
                }).catch(e => {
                    Toast('数据加载异常');
                    console.log(e);
                    reject();
                })
            } else {
                resolve();
            }
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}