// 取url参数
export function getQueryVariable(variable)
{
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return null;
}

// 验证一个值是否为空
export function isEmptyValue(value) {
    if (value == null) {
        return true;
    }
    let type;
    type = Object.prototype.toString.call(value).slice(8, -1);
    switch (type) {
        case 'String':
            return !value.trim();
        case 'Array':
            return !value.length;
        case 'Object':
            return isEmptyObject(value); // 普通对象使用 for...in 判断，有 key 即为 false
        default:
            return false; // 其他对象均视作非空
    }
}

export const getNowTime = () => {
    const time = new Date()
    let y = time.getFullYear()
    let m = time.getMonth()+1
    let d = time.getDate()
    let h = time.getHours()
    let mi = time.getMinutes()
    let s = time.getSeconds()
    m = m<10?`0${m}`:m
    d = d<10?`0${d}`:d
    h = h<10?`0${h}`:h
    mi = mi<10?`0${mi}`:mi
    s = s<10?`0${s}`:s
    return `${y}-${m}-${d} ${h}:${mi}:${s}`
}


export function isJSON(str) {
    if (typeof str == 'string') {
        try {
            JSON.parse(str);
            return true;
        } catch(e) {
            console.log(e);
            return false;
        }
    } else {
        return false;
    }
}
// url编码
export function urlEncode (str) {
    str = (str + '').toString();
    return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').
    replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
}
function AsciiToString(asccode){
    return String.fromCharCode(asccode);
}
// url解码
export function urlDecode(zipStr){
    var uzipStr = '';
    for (var i = 0; i < zipStr.length; i += 1) {
        var chr = zipStr.charAt(i);
        if (chr === '+') {
            uzipStr += ' ';
        } else if (chr === '%') {
            var asc = zipStr.substring(i + 1, i + 3);
            if (parseInt('0x' + asc) > 0x7f) {
                uzipStr += decodeURI('%' + asc.toString() + zipStr.substring(i+3, i+9).toString());
                i += 8;
            }else{
                uzipStr += AsciiToString(parseInt('0x' + asc));
                i += 2;
            }
        }else{
            uzipStr += chr;
        }
    }
    return uzipStr;
}
