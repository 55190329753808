const state = {
    wechat: true, // 是否处于微信客户端
    entryUrl:'',
    address:{},
    // province:'',
    talentIndex: 0,
    historyIndex: 0,
    lotteryIndex: 0,
};

const getters = {};

const mutations = {};

const actions = {
};


export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
}