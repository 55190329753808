import axios from 'axios';

const service = axios.create({
    baseURL: process.env.VUE_APP_URL,
    timeout: 30000,
});

// axios 拦截器
service.interceptors.request.use( config => {
    return config;
}, error => {
    console.log(error);
    return Promise.reject();
});

service.interceptors.response.use(response => {
    if(response.status === 200){
        return response.data;
    }else{
        Promise.reject();
    }
}, error => {
    console.log(error.response);
    if(process.env.VUE_APP_ENV === 'development'){
        switch (error.response.status) {
            case 503: // 维护中
                window.location.replace(process.env.VUE_APP_URL);
                break;
        }
    }
    return Promise.reject(error.response.status);
});



//默认其他请求格式
export default service;
//get
export function get(url,params={}){
    return new Promise((resolve,reject) => {
        service.get(url,{
            params:params
        }).then(response => {
            resolve(response);
        }).catch(err => {
            reject(err);
        })
    })
}
//post
export function post(url,data = {}){
    return new Promise((resolve,reject) => {
        service.post(url,data).then(response => {
            resolve(response);
        }).catch(err => {
            reject(err);
        })
    })
}

