<template>
    <div id="app" v-if="appInit">
        <div style="max-width: 750px;margin: 0 auto;">
            <index></index>
        </div>
    </div>
</template>

<script>
    import Index from './components/Index';
    import {mapState} from 'vuex';
    import {getQueryVariable, isJSON} from "./utils/public";
    import service from "./utils/request";
    import wx from 'weixin-js-sdk';
    import { Dialog, Toast } from 'vant';
    import {Storage} from "./utils/localstorage";

    export default {
        name: 'app',
        computed:{
            ...mapState(['common','users']),
        },
        components: {
            Index,
        },
        data(){
            return{
                appInit:false,
                storage:{},
            }
        },
        async created(){
            let that = this;

            // 初始化storage
            this.storage = new Storage();


            let env = process.env.VUE_APP_ENV;
            if(env === 'development'){
                // 测试环境直接认证通过，不能使用jssdk
                service.defaults.headers["X-Token"] = process.env.VUE_APP_DEFAULT_WE_TOKEN;
                localStorage.setItem('xToken', process.env.VUE_APP_DEFAULT_WE_TOKEN)
                // 定位
                // that.common.address.adcode = '110114';
                // that.common.address.province = '北京市';
                // that.common.address.district = '昌平区';
                this.appInit = true;
                await this.getUserInfo();
            } else {
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                    duration: 0,
                });
                // this.test()
                // return
                // 验证微信登录
                if(await this.verify() === -1){
                    Toast('用户信息加载失败')
                }
                // 验证sdk
                // await this.sdk();
                // 验证是否是微信浏览器
                let ua = window.navigator.userAgent.toLowerCase();
                that.common.wechat = ua.match(/MicroMessenger/i) && true;
                Toast.clear();
                this.appInit = true;
            }
        },
        methods:{
            async verify(){
                let that = this;
                return new Promise( async (resolve, reject) => {
                    // 检查是否已静默授权
                    let key = getQueryVariable('key')
                    let code = getQueryVariable('code')
                    let xToken = localStorage.getItem('xToken')
                    if(!xToken){
                        xToken = getQueryVariable('x-token')
                        if(xToken){
                            await that.$router.replace('/verify')
                        }
                    }
                    if (xToken) {
                        // alert('用户已经注册，直接添加请求头')
                        // 用户已经注册，直接添加请求头
                        service.defaults.headers["X-Token"] = xToken;
                        localStorage.setItem('xToken', xToken)
                        await that.getUserInfo()
                        resolve()
                    } else if (key && code) {
                        // alert('key和code存在')
                        that.get('/wx/loginByGzCode',{code:code,key:key}).then(async result=>{
                            console.log(result)
                            // alert(JSON.stringify(result))
                            if(result.respCode==='0'){
                                if(result.data.xtoken){ // 用户已经注册,直接在请求头添加X-Token
                                    // alert('用户已经注册,直接在请求头添加X-Token')
                                    localStorage.setItem('xToken',result.data.xtoken)
                                    service.defaults.headers["X-Token"] = result.data.xtoken;
                                    // await that.getUserInfo()
                                }
                                if (result.data.wxUrl){
                                    // 用户未注册，保存弹窗授权的url
                                    // alert('用户未注册，保存弹窗授权的url')
                                    localStorage.setItem('completeUrl2', result.data.wxUrl)
                                    await that.$router.replace('/verify')
                                    resolve()
                                }
                                resolve();
                            } else {
                                resolve(-1)
                            }
                        })
                    } else {
                        // alert('微信静默授权')
                        // 微信静默授权
                        // alert('/wx/1000/queryAuthorizeUrl接口传参：'+JSON.stringify({url: window.location.href,webGzCallback: window.location.href}))
                        that.post('/wx/1000/queryAuthorizeUrl',{url: window.location.href,webGzCallback: window.location.href}).then(result=>{
                            // alert('/wx/1000/queryAuthorizeUrl接口返回：'+JSON.stringify(result.data))
                            console.log(result)
                            location.replace(result.data.wxUrl);
                            reject();
                        })
                    }
                });
            },
            // 查询用户信息
            getUserInfo(){
                return new Promise((resolve, reject) => {
                    this.post('/member/memberapi/queryInfo').then(result=>{
                        // console.log(result)
                        if(result.respCode === '0'){
                            localStorage.setItem('userInfo',JSON.stringify(result.data))
                            resolve(true)
                        } else {
                            this.$dialog({message: '授权失败，请刷新页面重试:<br/>' + result.respMsg});
                            localStorage.clear()
                            // alert(JSON.stringify(result))
                            resolve(false)
                        }
                    }).catch(e=>{
                        resolve(false)
                    })
                })
            },
            sdk() {
                let that = this;
                return new Promise((resolve, reject) => {
                    // 记录进入app时的url
                    if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
                        window.entryUrl = location.href.split('#')[0];
                    }
                    // 进行签名的时候  Android 不用使用之前的链接， ios 需要
                    that.common.entryUrl =  window.entryUrl;
                    // 接入jssdk
                    that.post('/api/common/jsapi',{url:/(Android)/i.test(navigator.userAgent) ? location.href.split('#')[0] : window.entryUrl}).then(result=>{
                        if(result.code === 0){
                            wx.config(result.data);
                        }
                    });
                    wx.ready(function () {

                        wx.updateAppMessageShareData({
                            title: '来飞网霸王餐', // 分享标题
                            desc: '天了噜，全城的免费活动都在这里啦！还不来？', // 分享描述
                            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: 'http://lf-public.oss-cn-shenzhen.aliyuncs.com/upload/logo.jpg', // 分享图标
                            success: function () {
                                // 设置成功
                            }
                        })

                        wx.updateTimelineShareData({
                            title: '来飞网霸王餐', // 分享标题
                            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                            imgUrl: 'http://lf-public.oss-cn-shenzhen.aliyuncs.com/upload/logo.jpg', // 分享图标
                            success: function () {
                                // 设置成功
                            }
                        })

                        wx.getLocation({
                            type: 'gcj02', // 火星坐标
                            success: res => {
                                if (!res.longitude) {
                                    this.$dialog({message: '地址信息查询失败，请打开手机的定位功能后重新打开当前页面'});
                                    return;
                                }
                                that.post('/api/common/amap/regeo', res).then(result => { // 记录并解析地址
                                    if (result.code === 0) {
                                        that.common.address = result.data.regeocode.addressComponent;
                                        console.log(that.common.address);
                                        resolve();
                                    }
                                }).catch(e => {
                                    console.log('服务异常');
                                    console.log(e);
                                });
                            },
                            fail: res => {
                                this.$dialog({message: '地址授权失败，已将您的地址自动设置为北京市朝阳区'});
                                that.common.address.adcode = '110105';
                                that.common.address.province = '北京市';
                                that.common.address.district = '朝阳区';
                                console.log(res);
                            }
                        });
                    });
                    wx.error(function (res) {
                        console.log('服务异常');
                        console.log(res);
                        resolve();
                    });
                });
            },
        }
    }
</script>

<style>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
</style>
