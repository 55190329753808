const state = {
    lesson:[
        [
            '大家好，我是小菲。欢迎来到我的精品课堂。',
            '如果您的门店入驻了美团点评，相信您无时无刻不渴望自己的门店有一天能成为5星商户。商户的星级评分对自身经营非常重要：一是便于提升门店的线上排名和客户的购买转化率，获取更多的流量。二是便于提升品牌门店的知名度和影响力。五星优质评分到底对我们线上经营有何好处？遇到差评，低分我们该怎么办？今天，小菲为您独家揭秘，教你如何打造你的5星门店！',
            '美团点评致力于为广大用户在消费购物时，提供商户查找和客观评分的本地生活服务类平台。平台上商户的星级是依赖于用户评论时提供的打分，系统结合多种因素自动综合计算得出的。每条评论都有一定的权重，整个过程无人工干预，算法对全频道的所有商户都是公平一致的。正是因为评比的公正公平，商户的星级和评分已成为了消费者决定到店的重要依据。也正是因为如此，商户的评分和星级是广大商家最关心的问题。',
            0,
            '很多商家问我，为什么我的商户星级提升不上去？我找了很多亲朋好友帮忙点评，为什么不显示或者过几天时间后就突然消失在点评区？我想告诉你的是：美团、大众点评这么大的平台，能有今天的成就，绝非是偶然。改版后的大众点评更多是站在用户的角度上考虑问题，自然针对那些刷好评的商家肯定是有他的检测方法和技术。那么，接下来肯定会有很多商家想问：美团点评的通过什么方法检测到违规点评行为的呢？在这里，我想告诉大家的是：首先，美团点评计算商户星级的算法和检测违规的技术是公司的最核心机密，美团点评公司从未对外披露，外部的公开资料也从来查阅不到相关信息。',
            '很多商家不重视商户的基础数据，而直接上点评，对于优质点评数量如痴如醉。所以，我经常听说有商家用礼品来换取客户点评或者找员工找朋友帮刷好评，以为多刷点好评，商户的星级和评分会很快提升。这是大错特错的。为什么这么说呢，因为平台有自己复杂的数据审核机制，还有二次人工审核。评价商户的好与劣，主要看商户后台数据，而后台数据是包含多个维度的，每个维度的数据又相互关联，整体构成了一套复杂的审核模型。单纯的提升点评量不但会造成大多数的点评没有被计入星级评分计算；情形严重者，平台会根据《商户诚信公约》对商户进行降星或星级清零处理。那么，商户的基础数据都包含哪些呢？它们分别是：<b>商户的曝光量、访问量、收藏量、分享量、预约量</b>。请大家试想一下：如果一个商户每日的访问量才50个点击，而当日的点评量新增了25个，也就是50个人浏览过该商户的页面，其中就有25个人最终完成了到店、消费和点评。显然，这样的数据是不符合常理或者远超出了平台整体的平均值的。平台很轻易的通过大数据技术抓取到这样的操作而判成违规。',
            1,
            '每个平台都有他的规则，我们既要了解他的规则，又要遵守他的规则。遵守规则是为了诚信经商，不投机取巧；了解规则是为了在提升自我的道路上有的放矢，快速晋级，使自己立于不败之地。',
            '通过以上的学习，小菲希望大家能对美团点评的平台规则有深入的了解。在接下来的课程中，小菲会逐一给大家揭秘商户星级评定方法、星级下降的原因、霸王餐的玩法以及当我们收到差评时该怎么操作。敬请收听，我们下节课再见吧。',
        ],
        [
            '大家好，我是小菲。欢迎来到我的精品课堂。',
            '商户的星级和评分可以帮助我们获取更多流量，维护新老客源，对自身经营非常重要。今天，小菲总结了这份星级提升指南，希望帮助大家了解美团点评的商户星级规则，解决日常运营中的常见问题。',
            '首先要了解，美团侧与大众点评侧的星级评分体系是各自独立的！两个平台所使用的星级算法不同，对应星级和评分没有关联性。我们今天重点讲解大众点评的商户星级规则',
            '商户星级和评分的计算依赖于用户评论时提供的打分，结合<b>用户可信度、商户诚信度、评论质量、评论时间、评论量</b>等多种因素自动综合计算的，每条评论都有一定的权重，整个过程无人工干预，算法对全频道的所有商户都是公平一致的。那么，大众点评商户星级是如何评定的？',
            '1.大数据为基础:使用商户下全量打星评价，剔除作弊数据，反映客观的商户情况',
            '2.公平公正:星级的计算客观中立，不接受任何形式购买高星',
            '3.每日更新：星级每天会根据商户下新增点评更新计算，由于审核时间延迟，所以仅计算4天前的打星点评',
            '4.规则公证:大众点评会请第三方公证机构对星级计算规则进行权威的监督公证',
            '5.精细化模型计算:星级不是简单的算术平均，而是使用模型进行加权平均计算，考虑的权重因素包括：',
            '<b>第一、时间因子</b>：近期新提交的点评更重要。越老的点评权重越低，对星级的影响越小',
            '<b>第二、诚信因子</b>：体验后的真实评价才会纳入计算。那么，什么是“体验后的真实评价”？我们所说的“体验后”，通常是指能证明点评者真实到店的行为，包括使用了买单、代金券、团购或打卡等功能，或者所上传的点评照片或视频与商户的产品相关，且照片或视频与店内其他点评相似度较低',
            '<b>第三、用户因子</b>：用户的会员等级越高，权重越大。同品类历史评价越多的用户，参考价值越高。比如，会员等级Lv8的用户点评所占的权重比Lv4的权重大很多。一个Lv8的优质好评，对商户的星级影响很大，而且其点评内容也更令其他消费者信服。再比如，同样是会员等级为Lv8的两个用户，其中一个经常点评火锅，另一个经常点评美容美发，那么点评火锅的用户在点评餐饮类商户时，会比另外一个用户权重更大，反之亦然。',
            '<b>第四、质量因子</b>：对评论进行质量打分，优质的带图、长点评对星级有更高的影响。大众点评优质评价基本门槛为100字＋3张图片或100字+1个短视频。为了让点评内容更加优质，建议点评字数最好写到150字以上，图片数量以9张为最佳，如发短视频，时长不要低于5秒。点评内容要与商户主题息息相关。有关如何打造精彩的点评内容，小菲会在后续的课程中为大家详细讲解。',
            '<b>第五、数量因子</b>：其他因素相同，点评数越多的商家星级越高。这点很好理解，我在这里就不过多展开讲了。',
            '总结一下：大众点评商户的星级和评分，依赖于用户点评时的评分。商户评分不是用户评分简单的算数平均，而是加权平均。影响评分权重的因子有5个，分别是：时间因子、诚信因子、用户因子、质量因子和数量因子。平台会综合这些因子对商户进行最终评定，星级反映的是商户在同类目下的相对水平。这其中任何一个因素都有可能会影响商户总体星级评分的变化。',
        ],
        [
            '大家好，我是小菲。欢迎来到我的精品课堂。',
            '近期，有商家朋友找我聊天，反馈说：他的门店近期也是有人点评的，可是商户星级却下降了。今天，小菲单独做出一堂课，单独为大家详细讲解商户星级下降的原因以及如何提升我们商户的星级。',
            '首先，商户的星级是根据用户专业度，评论质量，添加时间，诚信度等计算出来的综合分数，再根据商户是否达到同类目下特定分数进行最终星级评定。商户星级反映的是一个相对同类商户的水平，任何一个因素的变化，都会导致商户星级的下降。因此，并非近期没有差评，星级就不会下降；也并非近期有好评，商户星级就会立即上涨。若大家对于星级的变动尤为关注，可以从以下五点原因自查：',
            '第一、近期有较多低分评价甚至极端差评，则会导致评分下降。若要恢复需要商家增加有效且高质量评价。',
            '第二、近期有较多评论被诚信部门屏蔽。诚信部门会定期地审核和识别商户评论中对用户帮助不大的评论，这些评论将不计入星级计算。',
            '第三、近期点评数增长很缓慢。',
            '第四、近期有刷单或违规操作，会导致评论被屏蔽，造成评论数减少。',
            '第五、类目的迁移，会影响商户星级的变动。类目迁移会影响星级的排名，排名会在新的类目下重新计算。商户合并拆分会导致商户下评论的迁移，因此也会影响星级。类目迁移、商户合并拆分，星级重新计算会有2天延迟，请谨慎修改。',
            '讲到这里，我想很多商家朋友不禁会问，到底如何提升自己门店的星级？小菲在这里告诉大家！',
            '第一、商户服务质量够不够好，好评够不够多，差评够不够少。说白了，就是你要增加好评的数量，减少差评的数量。',
            '第二、发布评价的用户是否值得信任。也就是发布评论的人是否是大V，亦或是否被平台风控过。',
            '第三、点评的内容是否图文并茂。你需要有图有文或者有短视频，才能是优质好评。我们之前讲过，优质评论的权重比普通评论大很多。',
            '第四、点评数量是否足够。如果门店的总体点评数量过少，那么及时有新增的优质好评，系统给高分也是非常谨慎的，分数会略低一点。',
            '第五、近期是否有新增评价。',
            '以上因素，一项做得不够，都有可能导致商户星级的下降。反之，我们朝着以上各方向去努力做好，即可快速提升自己的商户星级。',
            '本节课的最后，我来回答一下上一期部分商家朋友的问题：',
            '<b>问题1、</b>为何我的商户单项分高，商户星级却不高？',
            '这里，小菲告诉大家：单项分和星级是独立计算的，相互之间没有影响。如：口味 7.1 环境 7.2 服务 6.5 这个是单项分。对应的商户评分: 差=1分；一般=2分；好=3分；很好=4分；非常好=5分。系统会根据用户对总体的打分以及口味，环境，服务的单项打分，进行商户星级计算。',
            '<b>问题2、</b>为什么我的门店有评价却没有星级？',
            '如果遇到这种情况，可能是以下几点原因造成的：',
            '第一，在商户产生第一条评论的4天后则会出现星级。没有点评时，系统为了给用户提供更多的参考信息，会由科学的计算公式、结合商户的自身情况（如连锁或附近商户信息），以及同行业网站信息，补充的一个预设星级。',
            '第二，之前无评论时已经出现星级的商户，有部分数据来源于历史数据或对应平台数据补星，在商家产生评价满足计算要求后，补星数据将清空，重新使用商户评价进行计算。',
            '第三，商家之前有过作弊行为，被诚信部门发现，首次作弊将被7天不计算星级，同时推送处罚消息至商家后台。7天后，将违规评论删除后重新计算星级。',
            '<b>问题3、</b>门店评价为什么会自动消失？',
            '小菲在这里告诉大家：一般用户发出评价后，机器逻辑会先过一遍，部分评价会被审核屏蔽，另外涉及违规的会交给人工查看。如商家对评价状态有疑问可在线申诉。',
            '申诉时，尽量提供小票/收据/发票/截图/录音/录像等或者相关合同的照片，以便核实，会大大提高处理效率。',
            '好了，今天我们关于商户星级变化的讲解就到这里，如果大家有什么疑问，可以在来飞网公众号给我直接留言或者与我们在线客服沟通咨询，我们下次课再见。',
        ],
        [
            '大家好，我是小菲。欢迎来到我的精品课堂。',
            '这一期，小菲单独做一期有关大众点评霸王餐活动的专题讲座，给大家详细讲解一下霸王餐的功能，霸王餐的参与方法，以及霸王餐对于商户星级的提升是否有更好的益处。',
            '霸王餐是美团点评的一个产品功能，比较适合新店做营销活动。商户在平台上发布免费的试吃活动，平台会挑选合适的VIP优质会员参加抽取霸王餐，抽中的会员，即可凭中奖号码到店兑换免费霸王餐。',
            '霸王餐一共有四个作用：',
            '第一个作用就是：抢占优势位置。也就是优势的一个曝光位。',
            '第二个作用：吸引新客户到店。',
            '第三个：积累好评。',
            '第四个：好评权重高',
            '首先先讲一下第一点：抢占优势曝光位。美团和大众点评两个平台，为参与霸王餐活动的商户提供一个专属的曝光位置。大家打开美团、大众点评APP，就可以看到在首页中间的位置，就有一个显著的霸王餐LOGO。这就是霸王餐的一个专属位置，每天会有巨大的流量涌入霸王餐页面。平台通过大数据技术，将门店智能的推荐给精准用户。把美酒搬出深巷，让商家走到人前！这就是霸王餐的抢占优势曝光位置的作用。',
            '第二个作用：可以吸引新客户到店。讲到这里，小菲要提一下：对于许多新店来说，参与霸王餐活动可以快速聚集人气，这是大家共同的认知。然后，还可以增加自己商户的评价。比如，有些消费者可能担心没去过的门店体验不好，白白破费，而放弃尝试新店。霸王餐就是要零元消费，可以打消消费者的这个疑虑，吸引顾客到店，进而进行转化。',
            '霸王餐的第三个作用是：积累好评。大部分被霸王餐吸引来的消费者，在免费体验后，会耐心的对商家的服务、环境、菜品的口味等等做点评。会附上精美的图片或短视频。这些优质点评对商家的星级提升大有好处，同时，这些点评又可以作为其他消费者的决策参考，吸引更多的普通消费者到店。',
            '当然了，各位商家参与霸王餐的意愿都是希望能积累好评，但是如果服务不到位、菜品不可口，也是有可能被用户差评，反而影响商户星级的提升的。所以说，霸王餐是一把双刃剑。大家在参与霸王餐之前，一定要先练好自己的“内功”，所谓内功，即门店的环境和服务、菜品的口味等等。霸王餐只能给商家带去流量，而这些流量是否能转化成好评或者复购，那要看各位商家自己的运营能力。',
            '参与霸王餐的第四个优势是：好评权重高。这里，小菲给大家解释一下，并不是参与了霸王餐的点评比没参与霸王餐的点评权重高，而是因为霸王餐的参与者多为美团点评资深玩家，会员等级相对较高，他们的评论自然权重较高。而且这些高级会员通常有较多关注的粉丝，当他们发布点评后，系统会将点评内容定向推送给他的粉丝，这样可以额外增加门店的曝光。这既是大V带客的原理，小菲会在后续的课程中重点给大家讲解什么是大V带客，在这里就先不详细讲解啦。',
            '讲完了美团点评官方推出的线上的霸王餐活动，我们再说说有些商家自己组织的线下霸王餐活动。所谓的线下霸王餐，即商家不通过美团点评平台的报名发布霸王餐活动，而是私下邀约一些消费者到店免费体验，然后要求体验后写优质点评作为交换。但是，相信很多商家做了这样的活动后，星级不但没有提升，甚至还收到了平台的扣除诚信分的警告。',
            2,
            '这是为什么呢？这是因为商家对平台的规则和监测技术不了解，盲目追求好评造成的。商家的此种行为早已被平台通过技术监测到了。《商户诚信公约》上明确规定商家私下组织免费试吃换取好评属于违规行为。参与此类行为的商家，会被平台扣除评价诚信分6分！扣分会受到商户星级隐藏、排序降权、处罚期内所有点评不展示等处罚。',
            '平台肯定不会派工作人员到店检查，那么他们是怎么监测到的呢？相信很多商家想知道答案。这里，小菲简单的给大家讲解一下。大家设想一下线下组织霸王餐的场景：商家花出大概一桌饭菜的成本，邀请多名参与者到店吃饭，饭前拍照，然后上传点评。那么，首先这些参与者上传的照片资料相似度很高，时间也非常临近，门店的好评增长是激增的，而非平稳增长。这些异常数据，后台是可以轻易抓取得到的。一旦被平台抓取到后，平台会将可疑商家推给平台的人工审核，人工审核后，确实属于违规行为，即对该商家实施了惩罚措施。',
            '商家想要获得好评的心情是非常能够理解的，但是还是要记住小菲告诫大家的那句话：我们既要了解平台的规则，又要遵守平台的规则。获取好评，要在平台规则下，最大限度最高效率的获取好评，这样才可以长长久久，让你的门店可以永远立于不败之地。\n' +
            '讲到这里，商家朋友们肯定想问：难道就没有更好的办法获取好评了吗？办法是有的，但一定要在充分了解平台规则的前提下来操作，具体方法，如果各位商家有兴趣了解，可以办理来飞网会员，添加我们的门店运营咨询师微信，他们会给您提供1对1的免费咨询服务。好了，今天的课程，小菲就给大家讲到这里，我们下节课再见。',
        ],
        [
            '大家好，我是小菲。欢迎来到我的精品课堂。',
            '最近有很多商家朋友问我一些关于差评的事情。差评对于商户的星级会更有影响。通常，商户每收到一个差评，需要用多个好评才能将平均分背回来。今天，小菲会花一期时间，给大家讲解一下，如何处理差评，以及遇到了竞争对手的恶意差评，我们该如何应对。',
            '首先，有一点大家要理解，那就是：无论你有再好的产品或者再专业的服务，再兢兢业业也都可能因为一个小的一个细节，没有照顾到消费者，他就会给写差评。这都是很正常的。但是，如果没有处理好就很有可能前功尽弃满盘皆输。我知道有很多教育行业因为差评直接倒闭的，有很多。无论是教育行业还是其他行业的门店，或多或少都会受到顾客差评的影响。那么商家对于差评要如何应对呢？接下来，就是我要讲的。',
            '首先，我们需要了解一下消费者给差评的几个常见理由。大家可以记一下，一共有五点：',
            '第一点是：<b>价格或者服务未按照事先约定履行</b>。我们举个例子：商品价格在购买的时候，它的团单价格是90。而到店了以后，你非让我付100，那么我很容易就给你差评。或者我到店以后发现你店里的价格是30，比约定的价格还要低，那我很有可能就给你差评的。或者我们约定的服务，比如按摩服务，因为你店里当时没有技师，你非要给我做脸部护理，那就很有可能给你差评，因为我是来体验我约定的价格或者服务的。',
            '第二点是：图片文字和现场或者与实物不符，或者产品没有效果。就是你上传的环境图是通过百度或者其他网站上Down下来的，与实际一点都不符，他就很有可能给你差评。',
            '或者是我要享受的服务你当时给我拍摄的产品或者仪器与你实际操作的仪器不一致，消费者看到这些就很有可能在消费完了之后给你写差评。',
            '或者使用的产品让我突然过敏了，这都很有可能会给你写差评。或者没有效果。就是你当时在上面跟我说的一次效果减肥能减五斤，但是实际上没有这个效果，当然，我说的可能比较夸张，但就是这个意思。',
            '第三点是：服务或者流程偷工减料。就是在服务过程中你说是90分钟或者流程有这样那样的环节，但是实际流程中并没有，这就很有可能给你差评。',
            '第四点是：服务人员的态度差很冷漠。这都很有可能给你写差评的。',
            '第五点是：推销问题。比如最常见的，在理发店里理发师强推会员卡，这样会引起消费者不满会给你差评。现在很多客户都是理性消费的。他很难在现场服务完一次以后就购买你大额款项的产品，这是很难做到的一件事情。',
            '我在重复一遍：一共有五个引起差评的一个理由。第一个，价格或者服务未按照事先约定履行；第二个，图片文字与现场实物不符，或者消费者对产品不满意没有效果；第三个，服务或者流程偷工减料；第四个、工作人员的态度差；第五个、过度推销。',
            '如果确定上述的原因引起的差评，你就需要自己进行深刻的反思了，你需要在店铺设施上、服务上、价格上或者是营销上，你就需要注意了，怎么去减少或者杜绝差评的发生。',
            '我们如果遇到这种差评，我们应该如何见招拆招呢。有几个实用的方法，我总结了一下，一共有四点：一是及时处理、二是仔细聆听、三是真诚的致歉、四是改善服务。我们具体讲一下：',
            '1.及时处理。当你看到差评或者好评都要第一时间及时回复并且联系，尤其是差评。这个时间段客户的意见会非常的大，而你如果第一时间回复了以后可能会让这个顾客的怒气降低。这是非常重要的一点。',
            '2.聆听。不要打断消费者的反馈或者吐槽。这个过程要是打断的话，很容易引起争吵。顾客只是在表达不满，你认真听，然后收集事实就好了。',
            '3.真诚的致歉。不管是否是你的原因造成的这些投诉，都不要责备消费者，这样消费者才会感受到你对他的重视，态度比较诚恳。你要承认你的问题，及时给出一个解决方案并且可以邀请他下次到店，免费服务。这是非常重要的。一定要真诚到道歉，不要与客户吵起来。',
            '4.改善服务。其实，客户所提出的建议或者不满都是你的门店改善自身服务的一个依据。你可以通过这些评价了解自己门店有哪些不足，然后做一个针对性的改善。',
            '如此一来，才能让门店的服务做得更好，顾客也满意了，这样口碑也可以建立起来了。',
            '其实美团点评差评功能最初的设计是为了督促卖家提高产品和服务质量的，但有时候会被“杠精”利用。他们给门店打出了恶意差评或者想敲诈勒索或者恶意竞争或者打击报复，这个我们接下来也会讲。',
            '写差评，那么只能等着商户上门求情了。因为可能会影响商户的流量，或者有的商户因为差评改名或者关店，这都不是开玩笑的。所以对于恶意差评一定要坚决抵制，美团点评官方也会协助我们抵制恶意差评。那么，什么样的差评属于恶意差评？一共有以下几点：',
            '第一点：商家员工或者离职员工的报复差评。因为有你把他辞退了，他会进行一些报复性差评。',
            '第二点：竞争对手的恶意差评。这些都是可以举报的。',
            '第三点：遇到消费者勒索。这点，小菲要重点说一下。有很多商家为了息事宁人，遇到勒索会顺从的给他钱，以想了事。这个东西他不会有个完结的，他会一直跟你要钱，所以这个是坚决杜绝的。在他第一次勒索你的时候，就向美团大众点评客服打电话，提供这些证据，进行反馈。然后，当你提交了恶意差评和相关信息后，一般1到2个工作日就可以处理完毕。遇此情况，大家一定要对差评说“不”，一定要为自己伸张正义，而不要被这个差评所勒索。',
            '好了，以上就是小菲今天跟大家分享的内容。也是本次系列课程的最后一堂课。相信通过不断的学习，大家会逐渐掌握美团点评的星级评分规则，会逐渐掌握提升星级的攻略，会逐渐掌握留住好评避免差评的方法。大家后续有疑问，随时联系来飞网平台的在线客服或加入我们的会员，我们会有专业的咨询师1对1帮大家解答门店运营的各种问题。祝愿各位商家早日打造出属于你的五星门店，谢谢大家！',
        ],
    ],
};

const getters = {};

const mutations = {};

const actions = {};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}