import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export default new Router({
    mode:'history',
    base:process.env.BASE_URL,
    routes: [
        {
            path: '/',
            meta: {title: '知鸟公开课',keepAlive: true},//此组件需要被缓存
            component: resolve => require(['../components/qianliang/dashboard'], resolve),
        },
        {
            path: '/home',
            meta: {title: '知鸟公开课',keepAlive: true},
            component: resolve => require(['../components/qianliang/dashboard'], resolve),
        },
        {
            path: '/dashboard',
            meta: {title: '知鸟公开课',keepAlive: true},
            component: resolve => require(['../components/qianliang/dashboard'], resolve),
        },
        {
            path: '/verify',
            meta: {title: '知鸟公开课'},
            component: resolve => require(['../components/qianliang/verify'], resolve),
        },
        {
            path: '/collection',
            meta: {title: '收藏'},
            component: resolve => require(['../components/qianliang/collection'], resolve),
        },
        {
            path: '/mine',
            meta: {title: '我的'},
            component: resolve => require(['../components/qianliang/mine'], resolve),
        },
        {
            path: '/test',
            meta: {title: '跳转测试'},
            component: resolve => require(['../components/qianliang/test'], resolve),
        },
        {
            path: '/test2',
            meta: {title: '跳转测试_中转页'},
            component: resolve => require(['../components/qianliang/test2'], resolve),
        },
        {
            path: '/test3/:id',
            meta: {title: '跳转测试_中转页',keepAlive: true},
            component: resolve => require(['../components/qianliang/test3'], resolve),
        },
        {
            path: '/redirect/:id',
            meta: {title: '中转页',keepAlive: true},
            component: resolve => require(['../components/qianliang/redirect'], resolve),
        },
        // {
        //     path: '/',
        //     meta: {title: '知鸟公开课'},
        //     component: resolve => require(['../components/HomePage/Home'], resolve),
        // },
        // {
        //     path: '/home',
        //     meta: {title: '知鸟公开课'},
        //     component: resolve => require(['../components/HomePage/Home'], resolve),
        // },
        {
            path: '/auth',
            meta: {title: '绑定微信ID'},
            component: resolve => require(['../components/qianliang/auth'], resolve),
        },
        {
            path: '/qr_code',
            meta: {title: '绑定微信ID'},
            component: resolve => require(['../components/qianliang/qr_code'], resolve),
        },
        {
            path: '/withdraw',
            meta: {title: '提现'},
            component: resolve => require(['../components/qianliang/withdraw'], resolve),
        },
        {
            path: '/bill',
            meta: {title: '账单'},
            component: resolve => require(['../components/qianliang/bill'], resolve),
        },
        {
            path: '/team',
            meta: {title: '我的团队'},
            component: resolve => require(['../components/qianliang/team'], resolve),
        },
        // {
        //     path: '/all',
        //     meta: {title: '查看全部'},
        //     component: resolve => require(['../components/qianliang/all'], resolve),
        // },



        {
            path: '/subscribe',
            meta: {title: '关注来飞网'},
            component: resolve => require(['../components/Common/Subscribe'], resolve),
        },
        {
            path: '/history',
            meta: {title: '来飞网-活动进度'},
            component: resolve => require(['../components/Common/History'], resolve),
        },
        {
            path: '/buyVip',
            meta: {title: '来飞网-购买会员'},
            component: resolve => require(['../components/HomePage/BuyVip'], resolve),
        },
        {
            path: '/vipCard',
            meta: {title: '来飞网-会员中心'},
            component: resolve => require(['../components/Mine/VipCard'], resolve),
        },
        {
            path: '/mine',
            meta: {title: '来飞网-个人中心'},
            component: resolve => require(['../components/Mine/Mine'], resolve),
        },
        {
            path: '/courseDetail',
            meta: {title: '来飞网-课程'},//课程详情
            component: resolve => require(['../components/Course/CourseDetail'], resolve),
        },
        {
            path: '/courseBuy',
            meta: {title: '来飞网-课程'},
            component: resolve => require(['../components/Course/CourseBuy'], resolve),
        },
        {
            path: '/lesson',
            meta: {title: '来飞网-课程'},//课程学习
            component: resolve => require(['../components/Course/Lesson'], resolve),
        },
        {
            path: '/lessonDetail',
            meta: {title: '来飞网-课程'},//课程详情
            component: resolve => require(['../components/Course/LessonDetail'], resolve),
        },
        {
            path: '/protocol',
            meta: {title: '来飞网-黑卡会员服务协议'},
            component: resolve => require(['../components/HomePage/Protocol'], resolve),
        },
        {
            path: '/shop',
            meta: {title: '来飞网-我的店铺'},
            component: resolve => require(['../components/Shop/Shop'], resolve)
        },
        {
            path: '/shop/bind',
            meta: {title: '来飞网-店铺绑定'},
            component: resolve => require(['../components/Shop/ShopBind'], resolve)
        },
        {
            path: '/shop/goods/list',
            meta: {title: '来飞网-我的活动'},
            component: resolve => require(['../components/Shop/GoodsList'], resolve)
        },
        {
            path: '/shop/goods/create',
            meta: {title: '来飞网-编辑团购'},
            component: resolve => require(['../components/Shop/GoodsCreate'], resolve)
        },
        {
            path: '/explore/buy',
            meta: {title: '来飞网-达人探店'},
            component: resolve => require(['../components/Explore/ExploreBuy'], resolve)
        },
        {
            path: '/explore/apply',
            meta: {title: '来飞网-申请探店'},
            component: resolve => require(['../components/Explore/ExploreApply'], resolve)
        },
        {
            path: '/explore/detail',
            meta: {title: '来飞网-探店邀请'},
            component: resolve => require(['../components/Explore/ExploreDetail'], resolve)
        },
        {
            path: '/talent/list',
            meta: {title: '来飞网-我的认证'},
            component: resolve => require(['../components/Talent/TalentList'], resolve)
        },
        {
            path: '/talent/apply',
            meta: {title: '来飞网-达人认证'},
            component: resolve => require(['../components/Talent/TalentApply'], resolve)
        },
        {
            path: '/talent/history',
            meta: {title: '来飞网-点评补发'},
            component: resolve => require(['../components/Talent/TalentHistory'], resolve)
        },
        {
            path: '/talent',
            meta: {title: '来飞网-点评补发'},
            component: resolve => require(['../components/Talent/Talent'], resolve)
        },
        {
            path: '/talent/mine',
            meta: {title: '来飞网-点评补发'},
            component: resolve => require(['../components/Talent/TalentMine'], resolve)
        },
        {
            path: '/talent/task',
            meta: {title: '来飞网-点评补发'},
            component: resolve => require(['../components/Talent/TalentTask'], resolve)
        },
        {
            path: '/talent/upload',
            meta: {title: '来飞网-探店投稿'},
            component: resolve => require(['../components/Talent/TalentUpload'], resolve)
        },
        {
            path: '/lottery',
            meta: {title: '来飞网-霸王餐'},
            component: resolve => require(['../components/Lottery/Lottery'], resolve)
        },
        {
            path: '/lottery/apply',
            meta: {title: '来飞网-申请霸王餐'},
            component: resolve => require(['../components/Lottery/LotteryApply'], resolve)
        },
        {
            path: '/lottery/buy',
            meta: {title: '来飞网-购买霸王餐'},
            component: resolve => require(['../components/Lottery/LotteryBuy'], resolve)
        },
        {
            path: '/lottery/detail',
            meta: {title: '来飞网-霸王餐'},
            component: resolve => require(['../components/Lottery/LotteryDetail'], resolve)
        },
        {
            path: '/lottery/confirm',
            meta: {title: '来飞网-霸王餐'},
            component: resolve => require(['../components/Lottery/LotteryConfirm'], resolve)
        },
        {
            path: '/lottery/confirm/success',
            meta: {title: '来飞网-霸王餐'},
            component: resolve => require(['../components/Lottery/LotteryConfirmSuccess'], resolve)
        },
        {
            path: '/lottery/consume',
            meta: {title: '来飞网-霸王餐'},
            component: resolve => require(['../components/Lottery/LotteryConsume'], resolve)
        },
        {
            path: '/lottery/success',
            meta: {title: '来飞网-霸王餐'},
            component: resolve => require(['../components/Lottery/LotterySuccess'], resolve)
        },
        {
            path: '/lottery/record',
            meta: {title: '来飞网-霸王餐'},
            component: resolve => require(['../components/Lottery/LotteryRecord'], resolve)
        },
        {
            path: '/lottery/mine',
            meta: {title: '来飞网-霸王餐'},
            component: resolve => require(['../components/Lottery/LotteryMine'], resolve)
        },
        {
            path: '/lottery/rule',
            meta: {title: '来飞网-霸王餐'},
            component: resolve => require(['../components/Lottery/LotteryRule'], resolve)
        },
    ]
})

