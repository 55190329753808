import Vue from 'vue'
import App from './App.vue'
import './assets/css/public.css';
// import remConfig from  './utils/remConfig';
// remConfig();

// vuex,vue-router
import store from './store';
import router from './router';

// clipboard2
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

// vant
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
});

// 深拷贝
Vue.prototype.deepCopy = function (obj, cache = []) {
  // typeof [] => 'object'
  // typeof {} => 'object'
  if (obj === null || typeof obj !== 'object') {
    return obj
  }
  // 如果传入的对象与缓存的相等, 则递归结束, 这样防止循环
  const hit = cache.filter(c => c.original === obj)[0];
  if (hit) {
    return hit.copy
  }
  const copy = Array.isArray(obj) ? [] : {};
  // 将copy首先放入cache, 因为我们需要在递归deepCopy的时候引用它
  cache.push({
    original: obj,
    copy
  });
  let that = this;
  Object.keys(obj).forEach(key => {
    copy[key] = that.deepCopy(obj[key], cache);
  });
  return copy;
};

// 封装请求
import {get,post} from './utils/request';
Vue.prototype.get = get;
Vue.prototype.post = post;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
