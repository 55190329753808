import {isEmptyValue} from '../../utils/public';
import request from '../../utils/request';
import {Toast} from "vant";

const state = {
    channels:[],
};

const getters = {};

const mutations = {};

const actions = {
    // 达人等级
    getExploreChannel(){
        return new Promise((resolve, reject) => {
            if(isEmptyValue(state.channels)){
                request.post('/api/common/enums', {type: 'explore_channel'}).then(result => {
                    if (result.code === 0) {
                        state.channels = result.data;
                        resolve();
                    } else {
                        Toast('数据加载异常');
                        reject();
                    }
                }).catch(e => {
                    Toast('数据加载异常');
                    console.log(e);
                    reject();
                })
            } else {
                resolve();
            }
        });
    },
};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}